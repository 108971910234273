import { promotionCenterNew } from '../../common/promotion/indexNew'
import { cartPromotionMapHandle } from '../../cart_new/utils/promotionMapHandle'
import schttp from 'public/src/services/schttp'
import { getCouponByCodes } from 'public/src/pages/user_prime_new/fetch.js'
import {
  handleTspLabels,
  handleShipInterceptGoods,
  handleShipFreeMall,
  handleCartShipFreeMall,
  isShippingMethodDisabled,
} from '../config/tools'
import * as api from './api'
import { getXtraDisplayData, updateXtraCouponList } from 'public/src/pages/checkout/config/tools.js'
import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import promotionInfo from 'public/src/pages/checkout/hooks/promotionInfo.js'
import { shippingCenter } from 'public/src/pages/common/shipping'
import { fetchCouponListNoticeApi } from 'public/src/services/api/pay/checkout.js'
import { exposeScenesAbtAggregator } from 'public/src/pages/checkout/hooks/EventAggregator.js'
import { useDayTime } from '../hooks/useDayTime'

const actions = {
  initCheckoutData({ state, dispatch }) {
    state?.checkout?.results?.carts?.carts?.forEach((item) => {
      item.promotionTag = {}
      item.promotionTypeIds = []
      item.flashType = ''
    })

    if(state.status) {
      state.status.pay['ebanx-oxxo'] = state.checkout.mall_caculate_info.isSupportOxxo || false
      state.status.hasAddress = state.checkout.default_address.addressId == 0 ? 0 : 1
    }

    if(state.caculateData) {
      state.caculateData.address_id = state.checkout.default_address.addressId
    }

    state.defaultSelectTokenPay = state.checkout.nowDefaultTokenByBin || {},

    dispatch('handleMallCartsList')
    dispatch('handleRapidInfo')
    dispatch('initAutoUsePoint')
    dispatch('initAutoUseWallet')
    dispatch('getCartPromotionInfo', { isSSR: true })
  },
  updateCart({ getters, state, dispatch, commit }, opts = {}) {
    const { noUpdataShipping } = opts
    const prime_product_code = state.caculateData.prime_product_code
    const checkout_no = state.buyNowNo
    const countryId = state.checkout?.default_address?.countryId

    schttp({
      url: `/api/checkout/cartsWithCouponGift/get?prime_product_code=${prime_product_code}&auto_use_coupon=0&checkout_no=${checkout_no}&userLocalSizeCountry=${getters.cacheLocalCountry}&countryId=${countryId}`,
    }).then((res) => {
      const cartList = res?.info?.carts || []
      const cartInfo = res?.info || {}
      const cartMallList = res?.info?.mall_list || []
      const mallShippingMethodsList =
          state.mallShippingMethods?.mall_list || []
      const caculateMallListLth =
          state.checkout.mall_caculate_info.mall_list?.length || 0

      if (res.code == 0 && res.info && cartList) {
        dispatch('checkoutPreHandlePromotion', {
          cartInfo,
          cartList,
        })
        state.checkout.results.carts.carts = cartList
        state.checkout.results.carts.mall_list = cartMallList
        state.checkout.results.carts.data = cartInfo
        commit('changeParamsStatus', { 
          type: 'editCartInfo', 
          params: { 
            cartLoading: false, 
          } 
        })
        // 获取tsp标签
        dispatch('handleCartsTspLabels').finally(() => {
          dispatch('handleMallCartsList')
        })
        opts.fn && opts.fn()

        // 购物车数据变动后，应重新请求支付方式列表，并重新判断一下valuting逻辑
        window.checkoutEventCenter.emit('checkout-update-payment', {
          updatePaypalGaVaultStatus: true,
        })
        // 如果可以购买超省卡，购物车数据变更需要重新调用超省卡试算
        if(getters.showXtra) dispatch('updateXtraPaymentsRight')

        // 如果存在 更新购物车 购物车里面的mall code不包含当前运输方式里面的mall code 就去更新运输方式
        // 限制商品弹窗批量已经更新，无需再次更新
        if (
          cartMallList.length &&
            mallShippingMethodsList.length &&
            !noUpdataShipping
        ) {
          let newCartMallList = cartMallList.map((item) => item.mall_code)
          let status = true
          mallShippingMethodsList.forEach((item) => {
            if (!newCartMallList.includes(item.mall_code)) status = false
          })
          // mall长度不一样 更新运输方式， 长度一样，mall有不一样 更新运输方式
          if (
            !status ||
              cartMallList.length != mallShippingMethodsList.length ||
              cartMallList.length != caculateMallListLth
          ) 
            window.checkoutEventCenter.emit('update-shipping-method', { address: state.checkout?.default_address })
        }
      }
      // 购物车接口回调
      opts.cartCallback && opts.cartCallback(res)

      // 不是日本，重调购物车，需要重新计算qs时效，日本的会在更新运输方式重新调取更新qs
      if( state.checkout.default_address.countryId != '108'){
        dispatch('newQuickShip')
      }
    })
  },
  async getCartPromotionInfo({ state, dispatch }, opts = {}) {
    let cartInfo = state.checkout?.results?.carts?.data || {}
    let cartList = state.checkout?.results?.carts?.carts || []
    dispatch('checkoutPreHandlePromotion', {
      cartInfo,
      cartList,
      forceRequest: !!opts.forceRequest,
      isSSR: opts.isSSR
    })
  },
  async checkoutPreHandlePromotion({ state, getters }, opts) {
    const { cartInfo, cartList } = opts
    let integrityPromotionInfo = {}
    if(opts.isSSR) {
      const promotionInfo = promotionCenterNew.handleSSRIntegrityPromotionInfo({
        res: state.checkout?.results?.integrityPromotionInfo,
        language: state.language,
        goods: state.checkout?.results?.carts?.carts || [],
        promotion_range: state.checkout?.results?.carts?.data?.promotion_range,
        locals: state.locals,
        isSuggested: getters.isSuggested,
      })
      integrityPromotionInfo = promotionInfo.integrityPromotionInfo
    } else {
      const promotionInfo = await promotionCenterNew.preHandlePromotion({
        goods: cartList,
        promotion_range: cartInfo.promotion_range,
        page: 'checkout',
        forceRequest: !!opts.forceRequest,
        isSuggested: getters.isSuggested
      })
      integrityPromotionInfo = promotionInfo.integrityPromotionInfo
    }

    state.integrityPromotionInfo = integrityPromotionInfo
    state.cartPromotionMap = cartPromotionMapHandle({ integrityPromotionInfo: integrityPromotionInfo }) // 判断活动是否满足 埋点用
    const { promotionList } = promotionInfo({ language: state.language, cartInfo: state.checkout?.results?.carts?.data, underPriceAbtValue: getters.underPriceAbtValue, flashSaleGroupList: getters.flashSaleGroupList, integrityPromotionInfo: integrityPromotionInfo })
    state.promotionList = promotionList
  },
  getCouponListNotice ({ state }) {
    const mallCodes = state.checkout?.results?.mallInfo?.mallInfoForSite?.map(item => item.mall_code)?.join(',') || ''
    let isVipFlat = (state.isPrimeMember || state.usedPrimeCode) ? 1 : 0
    fetchCouponListNoticeApi({
      isVipFlat,
      mallCodes
    }).then(res => {
      if (res.isOk) {
        state.couponListNotice = res?.data?.content || ''
      }
    })
  },
  getSubtotalPriceDetail ({ state }) {
    const { tax } = state.checkout?.mall_caculate_info

    state.subtotalPriceDetail.subTotalAmount = state.checkout?.mall_caculate_info.newSubTotal
    state.subtotalPriceDetail.ICMSAmount = tax.filter(item => item.taxPayType == 1 && item.taxType == 3 && +item.taxPrice.amount > 0)[0]?.taxPrice

    if(state.locals?.SiteUID?.includes('br')) {
      // 实缴个人增值税税金
      let acturalPersonVat = tax?.find(item => item.taxPayType == 2 && item.taxType == 2)?.taxPrice
      // 公司补贴增值税
      let companyVat = tax?.find(item => item.taxPayType == 1 && item.taxType == 3)?.taxPrice
      if(acturalPersonVat?.amountWithSymbol && companyVat?.amountWithSymbol) {
        schttp({
          url: '/trade-api/price/add',
          method: 'POST',
          data: {
            priceDTO1: acturalPersonVat,
            priceDTO2: companyVat,
            currency: gbCommonInfo.currency,
            scene: 'sortedPriceByPc'
          },
          useBffApi: true
        }).then(res => {
          if(res.code == 0) {
            state.subtotalPriceDetail.ICMSAmount = res.info?.resultPriceDTO
          }
        }).catch(() => {
        })
      }
      if(state.checkout?.mall_caculate_info?.newSubTotal && acturalPersonVat?.amountWithSymbol) {
        schttp({
          url: '/trade-api/price/add',
          method: 'POST',
          data: {
            priceDTO1: state.checkout?.mall_caculate_info?.newSubTotal,
            priceDTO2: acturalPersonVat,
            currency: gbCommonInfo.currency,
            scene: 'sortedPriceByPc'
          },
          useBffApi: true
        }).then(res => {
          if(res.code == 0) {
            state.subtotalPriceDetail.subTotalAmount = res.info?.resultPriceDTO
          }
        }).catch(() => {
        })
      }
    }
  },
  async getPrimeRightCouponInfo({ getters, commit }) {
    let primeRightCouponList = []
    if(!!getters.primeRightCouponCodeList.length) {
      const codes = getters.primeRightCouponCodeList.map((_) => _.coupon_code)
      const res = await getCouponByCodes(codes)
      if(res.code == 0) primeRightCouponList = res?.info?.list || []
    }
    commit('assignState', { 
      primeRightCouponList
    })
  },
  // 处理下单限制前置（运输方式9类型限制）弹窗展示
  async handleShipInterceptModal({ commit, state, getters }, { hintType, shippingMethod, mallCode }) {
    // 商品类
    if([1, 2].includes(+hintType)) {
      const limitGoods = await handleShipInterceptGoods(shippingMethod?.match_carts || [])
      commit('assignState', {
        showShippingLimitGoods: true,
        shippingLimitGoodsIsLoaded: true,
      })
      commit('changeParamsStatus', { 
        type: 'shippingLimitInfo', 
        params: { 
          hint: shippingMethod?.rule_info?.msg || '', 
          goodsList: limitGoods, 
          shippingMethod: shippingMethod, 
          mallCode, 
          orderLimitType: +hintType,
        } 
      })
    }
    // 地址类
    if (+hintType == 3) {
      // 运输方式是否为店配
      const isStoreAddress = TRANSPORT_TYPE_SHOP.includes(shippingMethod.transport_type)
      commit('assignState', {
        showShippingLimitAddress: true,
        shippingLimitAddressIsLoaded: true
      })
      commit('changeParamsStatus', { 
        type: 'shippingLimitInfo', 
        params: { 
          mallCode,
          shippingMethod,
          checkUpdateShippingMethod: false, // 是否需要在更新地址后重新请求运输方式等相关逻辑
          isStoreAddress,
          hint: shippingMethod?.rule_info?.msg || '',
          orderLimitType: +hintType
        } 
      })
    }

    // 店配地址不匹配
    if(+hintType == 7) {
      const mallShippingMethodsList = state.mallShippingMethods?.mall_list || []
      const shippingMethods = mallShippingMethodsList?.find(mall => mall.mall_code == mallCode)?.shipping_methods || []
      // 当前mall可用的店配运输方式（寻找第一个）
      const avaliableStoreInx = shippingMethods?.findIndex(_ => {
        const isDisabled = isShippingMethodDisabled({ item: _, orderInterceptByAbt: getters.orderInterceptByAbt })
        // 非当前运输方式、店配运输方式、可用、未走到hint_type=7类型
        return _.transport_type != shippingMethod.transport_type && TRANSPORT_TYPE_SHOP.includes(_.transport_type) && !isDisabled && !(_.is_available_msg == 9 && _.rule_info?.hint_type == 7)
      })
      const avaliableStoreInfo = avaliableStoreInx != -1 ? shippingMethods[avaliableStoreInx] : {}

      if(avaliableStoreInx == -1) { // 当前mall不存在可切换的可用店配运输方式，拉起地址
        commit('changeParamsStatus', { 
          type: 'shippingLimitInfo', 
          params: { 
            mallCode,
            shippingMethod,
            avaliableStoreTransportInfo: {
              shippingMethod: avaliableStoreInfo,
              index: avaliableStoreInx
            },
            checkUpdateShippingMethod: true, // 是否需要在更新地址后重新请求运输方式等相关逻辑
            hint: shippingMethod?.rule_info?.msg || '',
            orderLimitType: +hintType
          } 
        })

        window.checkoutEventCenter.emit('click-edit-store-address', { type: 'edit', otherData: {}, params: { mall_code: mallCode, transport_type: shippingMethod?.transport_type } })

        return
      } else { // 当前mall存在可切换的可用店配运输方式，拉起切换弹窗
        daEventCenter.triggerNotice({
          daId: '1-11-1-229',
        })
        commit('assignState', {
          showShippingLimitStore: true,
          shippingLimitStoreIsLoaded: true
        })
        commit('changeParamsStatus', { 
          type: 'shippingLimitInfo', 
          params: { 
            mallCode,
            shippingMethod,
            avaliableStoreTransportInfo: {
              shippingMethod: avaliableStoreInfo,
              index: avaliableStoreInx
            },
            checkUpdateShippingMethod: false, // 是否需要在更新地址后重新请求运输方式等相关逻辑
            hint: shippingMethod?.rule_info?.msg || '',
            orderLimitType: +hintType
          } 
        })
      }
    }

    commit('changeParamsStatus', { 
      type: 'shippingLimitInfo', 
      params: { 
        shippingLimitModalCount: ++state.shippingLimitInfo.shippingLimitModalCount
      } 
    })
    daEventCenter.triggerNotice({
      daId: '1-11-1-173',
      extraData: {
        rules_type: hintType,
        no: state.shippingLimitInfo.shippingLimitModalCount,
      }
    })
  },
  async updateXtraPaymentsRight({ state, commit }) {
    const xtraDiscountData = await api.queryXtraPaymentrights({ xtraProductBagInfo: state.xtraProductBagInfo, primeCode: state.usedPrimeCode, checkout_no: state.buyNowNo })
    const xtraDisplayCouponData = await getXtraDisplayData({ xtraProductBagInfo: state.xtraProductBagInfo, xtraDiscountInfo: xtraDiscountData })
    const res = await updateXtraCouponList(state.xtraProductBagInfo)
    
    // 超省卡信息归纳
    const xtraAllProductBagInfo = {}
    state.xtraProductBagInfo?.forEach(item => {
      const productCode = item.product_code
      const activityType = item.product_activity_type
      xtraAllProductBagInfo[productCode] = {
        ...state.xtraAllProductBagInfo[productCode],
        productInfo: item,
        discountInfo: xtraDiscountData?.allPaymentRightInfo?.[productCode] || {},
        couponListInfo: res?.[productCode],
        displayCouponInfo: xtraDisplayCouponData?.data?.[productCode],
        activityType
      }
    })
    const currentDefaultXtraProductCode = state.xtraProductInfo?.product_code
    // 更新checkout，防止其他地方使用state中的checkout
    const checkout = {
      ...state.checkout,
      results: {
        ...state.checkout?.results,
        xtraCouponList: res?.[currentDefaultXtraProductCode] || {},
        xtraDiscountInfo: xtraDiscountData,
        xtraDisplayCouponInfo: { ...xtraDisplayCouponData, data: xtraDisplayCouponData?.data?.[currentDefaultXtraProductCode] }
      }
    }
    const xtraDiscountInfo = xtraDiscountData?.allPaymentRightInfo?.[currentDefaultXtraProductCode] || {}
    const xtraDisplayCouponInfo = xtraDisplayCouponData?.data?.[currentDefaultXtraProductCode] || {}
    const xtraCouponList = res?.[currentDefaultXtraProductCode]?.couponList || {}
    const xtraAggregatedCouponList = res?.[currentDefaultXtraProductCode]?.aggregatedCouponList || {} 

    commit('assignState', { 
      checkout,
      xtraAllProductBagInfo,
      xtraDiscountInfo,
      xtraDisplayCouponInfo,
      xtraCouponList,
      xtraAggregatedCouponList,
    })

    // 更新会费券信息
    commit('upDateSceneStatusNew')
  },
  async handleCartsTspLabels({ state, getters }) {
    const isLowestPriceDays = getters.lowestPriceDays?.lowest_price_days == 'new'
    const behaviorShowAbt =  'fivestar,adds,sales2,buyagain,lowestprice'
    const goods = state.checkout.results.carts.carts
    await handleTspLabels(goods, behaviorShowAbt, isLowestPriceDays)
  },
  handleMallCartsList({ commit, state }) {
    let cartsList = state.checkout?.results?.carts?.carts || []
    let mallCarts = {}
    cartsList.forEach(item => {
      if (!mallCarts['c_' + item.mall_code]) {
        mallCarts['c_' + item.mall_code] = []
      }
      mallCarts['c_' + item.mall_code].push(item)
    })
    let res = []
    if(state.togetherBuy?.mallLacation?.length && state.togetherBuy.togetherBuyType){
      const keys = Object.keys(mallCarts)
      if(keys.length > state.togetherBuy.mallLacation.length){
        const find = keys.find(key => !state.togetherBuy.mallLacation.includes(key))
        find && commit('changeTogetherBuy', {
          mallLacation: [...state.togetherBuy.mallLacation, find]
        })
      }
      state.togetherBuy?.mallLacation.forEach((key)=>{
        res.push(mallCarts[key])
      })
    } else {
      state.togetherBuy?.togetherBuyType && commit('changeTogetherBuy', {
        mallLacation: Object.keys(mallCarts)
      })
      
      res = Object.values(mallCarts)
    }

    commit('assignState', { mallCartsList: res || [] })
  },
  async getShippingFreeMall({ state, getters, commit }) {
    let shippingInfoParams
    // 无地址时使用购物车数据传参，有地址后使用计价数据传参
    if(state.checkout?.default_address?.addressId == 0) {
      shippingInfoParams = handleCartShipFreeMall(state.checkout?.results?.carts?.data)
    } else {
      shippingInfoParams = handleShipFreeMall(state.checkout.mall_caculate_info?.cart_free_info_list)
    }

    // 当前选中的运输方式是店配且当前存在店配地址，传店配信息，否则传宅配信息
    const addressData = (getters.isShopTransit && !!state.storeData?.countryId) ? {
      country_id: state.storeData?.countryId,
      address: state.storeData,
    } : {
      country_id: state.checkout?.default_address?.countryId,
      address: state.checkout?.default_address?.addressId == 0 ? null : state.checkout?.default_address,
    }
    const shippingContentInfo = await shippingCenter.getMallShippingContent({
      mall: shippingInfoParams,
      country_id: addressData.country_id,
      address: addressData.address,
      fromCheckout: true
    })

    commit('assignState', { 
      shippingContentInfo
    })
  },

  handleSelectTokenPay({ commit }, defaultSelectTokenPay) {
    commit('updateDefaultSelectTokenPay', defaultSelectTokenPay)
  },

  handleUpdateIsShowTokenPay({ commit }, isShowTokenPay) {
    commit('updateIsShowTokenPay', isShowTokenPay)
  },
  handleRapidInfo({ state, commit }) {
    commit('assignState', { 
      buyNowNo: state.checkout?.checkout_no || '' 
    })
    state.caculateData.checkout_no = state.checkout?.checkout_no || ''
    if(typeof window == 'undefined') return

    commit('assignState', { buyNowFromUrl: sessionStorage?.getItem('buyNowFromUrl') || '' })
  },
  initAutoUsePoint({ state }) {
    const orderAutoPointsAbtInfo = state.checkout?.checkoutBFFAbtInfo?.orderautopoints?.param?.isAutoPoint
    const isAutoPoint = orderAutoPointsAbtInfo != 0
    state.caculateData.auto_use_point = isAutoPoint ? 1 : 0
    state.caculateData.auto_use_point_threshold = orderAutoPointsAbtInfo?.split('_')?.[1] || '0'
  },
  initAutoUseWallet({ state, getters, commit, dispatch }) {
    if (!getters.isAutoUseWallet) return
    const walletPrice = state.checkout?.mall_caculate_info?.walletPrice || {}

    if (walletPrice.amount > 0) {
      state.caculateData.use_wallet = 1
      state.caculateData.use_wallet_amount = walletPrice.amount
      commit('updateCheckoutState', { 
        key: 'walletData',
        val: walletPrice.amount 
      })
      state.status.isWalletAmoutHit = true
      state.status.success.wallet = 1
    }
    dispatch('analysisAutoUseWallet')
  },
  // 上报自动使用钱包埋点
  analysisAutoUseWallet({ state }) {
    if(typeof window == 'undefined') return

    exposeScenesAbtAggregator.addEvent({
      daId: '1-11-1-169',
      extraData: {
        scenes: 'walletBalance',
        type: state.checkout?.mall_caculate_info?.wallet?.subtractPrice?.amount > 0 ? 1 : 0,
        auto_wallet_result: state.checkout?.mall_caculate_info?.walletPrice?.amount > 0 ? 1 : 0,
      }
    })
  },
  sendAddressRefillPopUpAnalysis({ state }, { type, click_type, result }) {
    if(typeof window == 'undefined') return

    const { default_address } = state.checkout || {}
    const { countryId = '', countryName = '' } = default_address || {}
    let params = {}
    if(type === 'expose') {
      params.daId = '1-11-1-155'
      params.extraData = {
        country: countryName
      }
    } else if(type === 'click') {
      params.daId = '1-11-1-156'
      let extraData = {
        click_type,
      }
      if(countryId === '186') {
        extraData.abt_sence = state.checkout.checkoutBFFAbtInfo?.CompleteHighvalueInformation?.param?.is_required || ''
      }
      if(click_type === 1) {
        extraData.result = result
      }
      params.extraData = extraData
    }
    daEventCenter.triggerNotice(params)
  },
  getPromotionInfoBySiteUid({ state, commit }) {
    api.getPromotionInfoBySiteUid(state).then(res => {
      if (res.code == 0) {
        commit('assignState', {
          ...state.checkout,
          productPackageInfo: {
            ...state.checkout.productPackageInfo,
            freeGiftInfo: res
          }
        })
      }
    })
  },
  async newQuickShip({ state, commit }, qsParmas){
    try {
      // 等待请求完再获取节日化
      useDayTime({ state, commit })
      const mall_list = (carts) => {
        // id 标识，goods_sn => skc, sku_code => sku
        return carts.map(item => {
          const result = {
            mall_code: item.mall_code,
            store_code: item.store_code,
            item_no: item.id,
            skc_code: item.product.goods_sn,
            sku_code: item.product.sku_code,
          }
          return result
        })
      }
      const skuSkcQs = (mallList, data) => {
        const { selfEffect, storeEffectList, combine_transport_time_desc_replace, ...other } = data ?? {}
        const qsInfo = {
          ...other,
          combine_transport_time_desc_replace,
          last_quickship_tag: selfEffect?.tip ?? '',
          last_quickship_tag_en: selfEffect?.tipForEn ?? '',
          qs_mall_list_all: []
        }
        qsInfo.qs_mall_list_all = mallList.map(m => {
          const { mall_code, store_code, skc_code, sku_code, item_no } = m ?? {}
          const findData = (storeEffectList ?? []).find(f => f?.mallCode == mall_code && f?.storeCode == store_code)
          const { mallCode, storeCode, tip, extraInfo, type, tipForEn } = findData ?? { mallCode: '', storeCode: '', tip: '', extraInfo: '', type: '', tipForEn: '' }
          return {
            skc_code,
            sku_code,
            item_no,
            mall_code: mallCode,
            store_code: storeCode,
            product_quickship_tag: tip,
            product_quickship_tag_en: tipForEn,
            product_quickship_des: extraInfo,
            qs_time_type: type,
          }
        })
        return qsInfo
      }

      const address = state.checkout.default_address
      const carts = state.checkout.results.carts.carts
      const params = {
        addressInfoOther: address,
        has_shop_transit_address: !!state.storeData ? 1 : 0, 
        checkout_no: state.buyNowNo, scene: 'checkout',
        mall_list: mall_list(carts),
      }
      const transport_type = state?.defaultShipping?.[0]?.shipping_method?.transport_type || ''
      if(state.checkout?.default_address?.countryId == '108' && transport_type){
        Object.assign(params, { transport_type, ...qsParmas })
      }
      const info = await schttp({
        url: '/api/common/getAgingInfo/get',
        method: 'POST',
        data: params
      })

      if(info.code == 0){
        const result = skuSkcQs(params.mall_list, info?.info)
        commit('assignState',  { 
          qsMallList: {
            ...state.checkout?.qsMallList,
            ...result
          }, 
        })
      } else {
        console.error(`newQuickShip err`)
      }
    } catch (err) {
      console.error(`[cart getQsTimelinessInfo] 获取qs信息失败: ${err}`)
    }
  },
  async getNewGiftCardInfo({ state, commit }, { cardNo, cardPin } = {}) {

    const res = await schttp({
      method: 'POST',
      url: '/trade-api/order/checkout/gift-card',
      data: {
        card_no: cardNo,
        country_id: state.checkout.default_address?.countryId,
        card_pin: cardPin
      },
      useBffApi: true
    })
    const { code, info } = res
    if (+code === 0) {
      commit('assignState', {
        checkout: {
          ...state.checkout,
          newGiftcard: info
        }
      })
    }
    return {
      ...res,
      cardInfo: info?.card_info
    }
  }
}

export default actions

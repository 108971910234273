<template>
  <div
    v-expose="{
      id: '2-43-1',
      data: xtraOptionAnalysis
    }"
    class="checkout-xtra__wrap fsp-element new-xtra-wrap"
    :style="{
      '--title-color': cardBaseStyle?.titleColor,
      '--title-place-color': cardBaseStyle?.titlePlaceColor,
      '--sub-title-color': cardBaseStyle?.subTitleColor,
      '--sub-title-place-color': cardBaseStyle?.subTitlePlaceColor,
      '--protocal-color': cardBaseStyle?.protocolColor,
    }"
  >
    <img
      class="checkout-xtra__wrap-logo"
      :src="xtraLogoImg"
      alt=""
    />

    <div
      class="checkout-xtra__wrap-body"
      :style="xtraWrapBg"
      @click="clickXtra({type: 'all', xtraProductCodeInDrawer: productInfo.product_code })"
    >
      <div
        class="checkout-xtra__wrap-content"
      >
        <p
          class="checkout-xtra__NewWrapDesTitle"
          :class="{'disabled': notSupport || hasAddress == 0}"
          v-html="productTitle"
        >
        </p>
        <p
          v-if="cardBaseStyle?.showSubTitle"
          class="checkout-xtra__NewWrapDes"
          :class="{'disabled': notSupport || hasAddress == 0}"
          v-html="productSubTitle"
        >
        </p>

        <!-- 入口券列表 -->
        <EntryCouponList
          class="checkout-xtra__wrap-list" 
          :coupon-list-info="couponListInfo"
          :language="language"
          :display-coupon-info="displayCouponInfo"
          :used-xtra-code="usedXtraCode"
          :aggregated-coupon-list="aggregatedCouponList"
          :entry-coupon-style="entryCouponStyle"
          :gb-css-right="GB_cssRight"
          :show-new-style="showNewStyle"
          :light-effect="lightEffect"
          @click-details="clickDetails"
        />
        <!-- 产品包选项 -->
        <ProductBagSelected
          ref="xtraRadioSelectedRef"
          :language="language"
          :bubble-text="bubbleText"
          :logo-list="bubbleLogoList"
          :xtra-seleted="xtraSeleted"
          :not-support="notSupport"
          :is-has-auto-renewed-bag="isHasAutoRenewedBag"
          :payment-methods-props="paymentMethodsProps"

          :xtra-all-product-bag-info="productBagInfoList"
          :used-xtra-code="usedXtraCode"
          :limited-pay-type-list="limitedPayTypeList"
          :xtra-product-code-in-drawer="productCodeInDrawer"
          :is-show-auto-renewd-dubble="isShowAutoRenewdDubble"
          :style-config="productBaseStyle"

          @click-discount-tips="radioSelectClickDisountCb"
          @click-radio="clickXtra"

          @update-product-code-in-drawer="updateProductCodeInDrawer"
          @product-bag-click-event="productBagClickEvent"
          @auto-renewd-drawer-show="autoRenewdDrawerShow"
          @dialog-change-event="dialogChangeEvent"
        />

        <p
          class="checkout-xtra__wrap-policy"
          :class="{'disabled': notSupport || hasAddress == 0}"
        >
          <span 
            @click.stop="clickPolicy" 
            v-html="policyTips"
          >
          </span>
          |
          <span
            v-if="isShowAutoRenewedProtocol"
            class="auto-renewed-protocol"
            @click.stop="autoRenewalDialogVisible = true"
          >{{ language.SHEIN_KEY_PC_30954 }}</span>
          <template v-else>
            <span 
              class="polict-detail" 
              @click.stop="clickDetails"
            >{{ language.SHEIN_KEY_PC_26929 }}</span>
          </template>
        </p>
        <p
          v-if="notSupport && cardPay"
          class="checkout-xtra__wrap-disabled"
        >
          <sui_icon_reported_14px_1
            size="12px"
            color="#bf4123"
          />
          {{ template(nowPayment.title, language.SHEIN_KEY_PC_27083) }}
        </p>
      </div>
    </div>
    <ClientOnly>
      <div v-if="isClient">
        <SaverCouponDrawer 
          v-if="showXtraCoupon"
          :coupon-drawer-style="couponDrawerStyle"
          :style-config="productBaseStyle"
          :apply-coupons-vir="applyCouponsVir"
          :payment-methods-props="paymentMethodsProps"
          :is-has-auto-renewed-bag="isHasAutoRenewedBag"
          :disabled="notSupport || hasAddress == 0"

          :show-xtra-coupon="showXtraCoupon"
          :xtra-product-code-in-drawer="productCodeInDrawer"
          :product-info="productInfo"
          :xtra-product-info="xtraProductInfo"
          :language="language"
          :coupon-list-drawer="couponListDrawer"
          :auto-paymenods-guide-visible="autoPaymenodsGuideVisible"

          :bubble-text="bubbleText"
          :logo-list="bubbleLogoList"
          :xtra-all-product-bag-info="productBagInfoList"
          :used-xtra-code="usedXtraCode"
          :limited-pay-type-list="limitedPayTypeList"
          :is-show-auto-renewd-dubble="isShowAutoRenewdDubble"
          :scene-logic="sceneLogic"
          :xtra-product-normal="xtraProductNormal"
          :xtra-aggregated-coupon-list="aggregatedCouponListMap"

          :auto-payment-visible="autoPaymentVisible"

          :auto-renewd-drawer-show="autoRenewdDrawerShow"
          :update-product-code-in-drawer="updateProductCodeInDrawer"
          :dialog-change-event="dialogChangeEvent"

          @choose-xtra="chooseXtra"
          @set-xtra-coupon="setXtraCoupon"
          @product-bag-click-event="productBagClickEvent"
        />

        <!-- 自动续费协议弹窗 -->
        <AutoRenewedDialog
          :visible="autoRenewalDialogVisible"
          :language="language"
          :xtra-all-product-bag-info="productBagInfoList"
          @close-dialog="autoRenewalDialogVisible = false"
        />

        <!-- 合规法务弹窗 -->
        <s-dialog
          v-model:visible="discountTipsVisible"
          :type="'W480'"
          show-close
        >
          <p>{{ language.SHEIN_KEY_PC_27391 }}</p>
          <template #footer>
            <s-button
              :type="['primary', 'H44PX']"
              @click="discountTipsVisible=false"
            >
              {{ language.SHEIN_KEY_PC_15216 }}
            </s-button>
          </template>
        </s-dialog>

        <!-- 费用明细弹窗 -->
        <s-dialog
          v-model:visible="virtunalDiscountVisible"
          show-close
        >
          <template #icon>
            <img
              :src="imgAll.dialogHeaderBg"
              class="header-bg"
            />
          </template>
          <template #title> 
            <div class="header-bg-container">
              {{ language.SHEIN_KEY_PC_28828 }} 
              <div class="pickColor_xtra xtra-text">
                {{ newProductPriceInfo.reduce_price_with_symbol }}
              </div>
            </div>
          </template>
          <template #footer>
            <div class="virXrt_container">
              <div class="virXrt_line">
                <div class="virXrt-line_left">
                  <span class="virXrt-lineLeft_tips">{{ language.SHEIN_KEY_PC_28829 }}</span> 
                  <Icon
                    v-if="sceneLogic[productCode].iconDoubt"
                    name="sui_icon_doubt_16px_1"
                    size="16px"
                    style="cursor:pointer"
                    @click="clickDiscountTips($event, true)"
                  /> 
                </div>
                <div class="pickColor_xtra">
                  - {{ newProductPriceInfo._discountPrice }}
                </div>
              </div>
              <div class="virXrt_line">
                <div>{{ language.SHEIN_KEY_PC_28830 }}</div>
                <div class="pickColor_xtra">
                  - {{ newProductPriceInfo && newProductPriceInfo._conponPrice }}
                </div>
              </div>
            </div>
            <s-button
              :type="['primary']"
              width="100%"
              @click="virtunalDiscountVisible=false"
            >
              {{ language.SHEIN_KEY_PC_28839 }}
            </s-button>
          </template>
        </s-dialog>
      </div>
    </ClientOnly>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { template, debounce } from '@shein/common-function'

import { expose } from 'public/src/pages/common/analysis/directive.js'
import { daEventCenter } from 'public/src/services/eventCenter/index.js'
import { scenceBuildStatus } from './shared/utils.js'
import { getProductStyle, handleTotalPriceAnimate } from '../shared/utils.js'

import EntryCouponList from './EntryCouponList.vue'
import ProductBagSelected from './ProductBagSelected.vue'
import { Icon, sui_icon_reported_14px_1 } from '@shein-aidc/icon-vue3'
import { SIGNUP_PAYMENT_METHOD } from 'public/src/pages/common/constants.js'

daEventCenter.addSubscriber({ modulecode: '2-43' })

const { currency, currencies } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

export default {
  name: 'XtraOptions',
  directives: { expose },
  components: {
    SaverCouponDrawer: defineAsyncComponent(() => import('./SaverCouponDrawer.vue')),
    Icon,
    sui_icon_reported_14px_1,
    EntryCouponList,
    ProductBagSelected, 
    AutoRenewedDialog: defineAsyncComponent(() => import('./AutoRenewedDialog.vue')),
  },
  props: {
    notSupport: {
      type: Boolean,
      default: false
    },
    hasAddress: {
      type: Number,
      default: null
    },
    language: {
      type: Object,
      default: () => {}
    },
    productBagInfoList: {
      type: Object,
      default: () => {}
    },
    limitedPayTypeList: {
      type: Array,
      default: () => []
    },
    aggregatedCouponList: {
      type: Array,
      default: () => []
    },
    couponListInfo: {
      type: Array,
      default: () => []
    },
    displayCouponInfo: {
      type: Object,
      default: () => {}
    },
    cardPay: {
      type: String,
      default: ''
    },
    bubbleText: {
      type: String,
      default: ''
    },
    bubbleLogoList: {
      type: Array,
      default: () => []
    },
    productInfo: {
      type: Object,
      default: () => {}
    },
    paymentMethodsProps: {
      type: Object,
      default: () => {}
    },
    styleConfig: {
      type: Object,
      default: () => {}
    },
    goCouponPage: {
      type: Function,
      default: () => {}
    },
    usedXtraCode: {
      type: String,
      default: ''
    },
    titleInfo: {
      type: Object,
      default: () => {}
    },
    locals: {
      type: Object,
      default: () => {}
    },
    abt: {
      type: Object,
      default: () => ({})
    },
    nowPayment: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['changeUpdateCalcu', 'changeCouponInfo', 'updateCoreDataEvent', 'autoRenewdDrawerShow'],
  data() {
    return {
      isClient: false,
      xtraSeleted: false,
      autoPaymentVisible: false,
      showXtraCoupon: false,
      autoRenewalDialogVisible: false,
      discountTipsVisible: false,
      virtunalDiscountVisible: false,
      productCode: 'default',
      productCodeInDrawer: '',
      isReportOnceEvent: false,
      cardBaseStyle: {},
      entryCouponStyle: {},
      lightEffect: false,
      hasSlide: false,
    }
  },
  provide() {
    return {
      GB_cssRight: this.locals?.GB_cssRight
    }
  },
  computed: {
    // 新样式
    showNewStyle() {
      return this.abt?.saverOrderModule?.param?.saverordercouponstyle === 'highvalue'
    },
    showSlideEffect() {
      return this.abt?.saverOrderModule?.param?.saverorderminiaction === 'on'
    },
    // 新文案
    showNewMainTitle() {
      return this.abt?.saverOrderModule?.param?.saverordermaintext === 'new'
    },
    GB_cssRight() {
      return this.locals?.GB_cssRight
    },
    PUBLIC_CDN() {
      return this.locals?.PUBLIC_CDN
    },
    imgAll() {
      return {
        dialogHeaderBg: `${this.PUBLIC_CDN}/she_dist/images/checkout/xtra_header_bg-43453ce74b.png`,
      }
    },
    SHEIN_SAVER_ARTICLE_IDS() {
      return this.locals?.SHEIN_SAVER_ARTICLE_IDS
    },
    langPath() {
      return this.locals?.langPath
    },
    couponListDrawer() {
      const keys = Object.keys(this.productBagInfoList)
      const couponListMap = []
      keys.forEach(key => {
        couponListMap[key] = this.productBagInfoList[key].couponListInfo.couponList
      })
      return couponListMap
    },
    aggregatedCouponListMap() {
      const keys = Object.keys(this.productBagInfoList)
      const couponListMap = []
      keys.forEach(key => {
        couponListMap[key] = this.productBagInfoList[key].couponListInfo.aggregatedCouponList
      })
      return couponListMap
    },
    xtraProductInfo() {
      const keys = Object.keys(this.productBagInfoList)
      const couponListMap = []
      keys.forEach(key => {
        couponListMap[key] = this.productBagInfoList[key].productInfo
      })
      return couponListMap
    },
    productBaseStyle() {
      const baseConfig = {}
      const productList = this.xtraAllproduct?.map(m => m.product_code)      
      productList.forEach(productCode => {
        const priceColor = getProductStyle(
          productCode,
          this.styleConfig?.productPackageStyle?.basicConfigList,
          this.styleConfig?.productPackageStyle?.basicConfig
        ) || {}      
        baseConfig[productCode] = { showLabel: true, showLinePrice: true, ...priceColor }
      }) 
      return baseConfig
    },
    couponDrawerStyle() {
      const baseConfig = {}
      const productList = this.xtraAllproduct?.map(m => m.product_code)      
      productList.forEach(productCode => {
        const priceColor = {
          ...getProductStyle(
            productCode,
            this.styleConfig?.popupStyle?.basicConfigList,
            this.styleConfig?.popupStyle?.basicConfig
          ),
          ...getProductStyle(
            productCode,
            this.styleConfig?.popupStyle?.couponConfigList,
            this.styleConfig?.popupStyle?.couponConfig
          )
        }   
        baseConfig[productCode] = { showLabel: true, showLinePrice: true, ...priceColor }
      }) 
      return baseConfig
    },
    xtraWrapBg() {
      if (this.cardBaseStyle?.cardBgImg) {
        return {
          background: `url(${this.cardBaseStyle.cardBgImg}) 0% 0% / 100% 100% no-repeat`,
        }
      }
      return {
        background: '#FFF',
      }
    },
    xtraLogoImg() {
      return this.GB_cssRight ? `/she_dist/images/checkout/sui_img_sheinsaver_ar-0057104b62.png` : `/she_dist/images/checkout/sui_img_sheinsaver-3a1433be13.png`
    },
    productTitle() {
      let xtraDiscountTips =  this.language.SHEIN_KEY_PC_30074
      let caculateTips =  this.language.SHEIN_KEY_PC_30075
      let defaulTips =  this.language.SHEIN_KEY_PC_30185
      
      // 1. 使用的券是筛选出超省卡券的权益包卡券55
      // 2. 组装coupon_discount_info，根据筛选出的券，取对应discount_price值
      // 3. type: caculateTips取计价接口，xtraDiscountTips取试算接口，defaulTips兜底取超省卡查询接口
      const usedXtraCoupon = !!this.couponListInfo?.some(f => [55].includes(f.typeId))
      const caculateDiscount = this.titleInfo?.caculateDiscount  // 计算价格：勾选后
      const useCaculateTips = this.usedXtraCode && usedXtraCoupon && caculateDiscount
      const type = useCaculateTips ? 'caculateTips' : 'xtraDiscountTips'
      const discountInfo = this.currentProductDiscount // 试算价格：勾选前
      const discount = type == 'caculateTips' ? caculateDiscount : discountInfo?.total_discount
      const price = type == 'caculateTips' ? caculateDiscount?.amountWithSymbol : discountInfo?.total_discount?.amountWithSymbol
    
      if (this.showNewStyle) {
        const recouped = +discountInfo?.total_discount?.amount > +this.currentProductInfo?.product_price_info?.special_price
        if (recouped && this.showNewMainTitle) {
          // 一单回本，且命中新文案
          if (this.usedXtraCode) {
            // console.log('一单回本，勾选')
            // 勾选
            if (useCaculateTips) { // 使用了saver券
              caculateTips = this.language.SHEIN_KEY_PC_32837
            } else { // 未使用saver券
              xtraDiscountTips = this.language.SHEIN_KEY_PC_32838
            }
          } else {
            // console.log('一单回本，未勾选')
            // 未勾选
            xtraDiscountTips = this.language.SHEIN_KEY_PC_32836
          }
        } else if (+discountInfo?.total_discount?.amount && !recouped) {
          // 一单未回本，但有可用券
          if (this.usedXtraCode) {
            // console.log('一单未回本但勾选')
            // 勾选
            if (useCaculateTips) { // 使用了saver券
              caculateTips = this.language.SHEIN_KEY_PC_32839
            } else { // 未使用saver券
              xtraDiscountTips = this.language.SHEIN_KEY_PC_32840
            }
          } else {
            // 未勾选
            // console.log('一单未回本且未勾选')
            xtraDiscountTips = this.language.SHEIN_KEY_PC_32842
          }
        } else if (!discountInfo?.total_discount || !discountInfo?.total_discount?.amount || +discountInfo?.total_discount?.amount <= 0) {
          defaulTips = this.language.SHEIN_KEY_PC_32841
        }
      }

      const languageMap = {
        'xtraDiscountTips': xtraDiscountTips,
        'caculateTips': caculateTips,
        'defaulTips': defaulTips
      }

      // 兜底默认取值
      const xtraCouponListLen = this.displayCouponInfo?.count || 0
      if(!discount || !discount?.amount || +discount?.amount <= 0){
        if (this.showNewStyle) {
          return template(`<em>${this.productInfo?.equivalent_value_with_symbol || 0}</em>`, `<em>${xtraCouponListLen || 0}</em>`, languageMap.defaulTips)
        }
        const roi_order_count = this.productInfo?.roi_order_count || 0
        return roi_order_count <= 0 ? '' : template(`<em>${roi_order_count}</em>`, languageMap.defaulTips)
      }

      // 新样式 + 未勾选产品包
      if (this.showNewStyle && !this.usedXtraCode) {
        return template(`<em>${price || 0}</em>`, `<em>${xtraCouponListLen || 0}</em>`, languageMap[type])
      }

      return template(`<em class="${this.showSlideEffect && this.usedXtraCode ? 'saver-title-animation' : ''}">${price || 0}</em>`, languageMap[type])
    },
    productSubTitle() {
      const languageMap = {
        'xtraDiscountTips': this.language.SHEIN_KEY_PC_30076,
        'caculateTips': this.language.SHEIN_KEY_PC_30077,
      }
      const xtraCouponListLen = this.displayCouponInfo?.count || 0
      return xtraCouponListLen ? `
        ${template(`<em>${xtraCouponListLen}</em>`, `${languageMap.xtraDiscountTips}`)}
        ${template( `<em>${this.productInfo.product_cycle_days}</em>`, `| ${languageMap.caculateTips}`)}
      ` : ''
    },
    policyTips() {
      const articleId = this.currentProductInfo?.article_page_id || this.SHEIN_SAVER_ARTICLE_IDS
      const url = `${this.langPath}/shein-saver-terms-and-conditions-a-${articleId}.html`
      return template(url, this.language.SHEIN_KEY_PC_27085)
    },
    xtraAllProductBagData(){
      return Object.values(this.productBagInfoList) || []
    },
    // 是否在超省卡中展示自动续费产品包的UI逻辑
    isHasAutoRenewedBag() {
      return this.xtraAllProductBagData?.some(i => i.productInfo?.product_activity_type == 1) 
    },
    // 在切换到自动续费产品包的时候，展示自动续费协议
    isShowAutoRenewedProtocol() {
      return this.isHasAutoRenewedBag && this.productBagInfoList?.[this.usedXtraCode]?.productInfo?.product_activity_type == 1
    },
    sceneLogic(){
      return scenceBuildStatus(this.xtraAllproduct)
    },
    currentProductInfo(){
      return this.usedXtraCode == '' ? this.xtraProductNormal[0] : this.sceneLogic[this.usedXtraCode]?.productInfo || {}
    },
    currentProductDiscount(){
      const code = this.usedXtraCode || this.xtraProductNormal[0].product_code
      return this.productBagInfoList[code]?.discountInfo || {}
    },
    applyCouponsVir(){
      return this.currentProductInfo._xtraStatusInfo.applyCouponsVir
    },
    newProductPriceInfo(){
      if (this.productCodeInDrawer) {
        return this.productBagInfoList[this.productCodeInDrawer]?.productInfo?.product_price_info || {}
      }
      return this.productBagInfoList[this.productCode]?.productInfo?.product_price_info || {}
    },
    isPreciseType() {
      return this.newProductPriceInfo.promotion_type == 5
    },
    canShowDiscountTips() {
      return this.isPreciseType &&
        !this.notSupport &&
        this.hasAddress != 0
    },
    isShowAutoRenewdDubble() {
      return Boolean(this.autoPaymenodsGuideVisible && this.autoPaymentVisible && this.bubbleLogoList?.length)
    },
    autoPaymenodsGuideVisible() {
      return !!this.xtraAutoPayLimitInfo?.length && !!this.xtraProductAuto?.length
    },
    xtraProductAuto() {
      return this.xtraAllproduct.filter(f => f.product_activity_type == 1)
    },
    xtraOptionAnalysis() {
      if(typeof window == 'undefined') return {}
      const product = this.currentProductInfo.product_price_info
      return { original_price: product?.price_local_with_symbol || '-', special_price: product?.special_price_with_symbol || '-', select_flag: this.xtraSeleted ? 1 : 0 }
    },
    currentCardPayRememberRadio(){
      return this.paymentMethodsProps?.signupPayVault?.[this.cardPay]?.radioType != 1 || 
        (
          this.paymentMethodsProps?.signupAccountInfo?.[this.cardPay]?.id && 
          this.paymentMethodsProps?.signupPayVault?.[this.cardPay]?.useOneTimePay
        )
    },
    // currentAfterpayRememberRadio(){
    //   return this.paymentMethodsProps?.afterpayVault?.radioType != 1
    // },

    // xtraAutoController start
    xtraAllproduct() {
      return Object.values(this.productBagInfoList).map(m => m.productInfo)
    },
    xtraProductNormal() {
      return this.xtraAllproduct.filter(f => f.product_activity_type != 1)
    },
    currentProduct() {
      return this.xtraAllproduct.find(s => s.product_code === this.usedXtraCode)
    },
    currentProductCycleDays() {
      return this.xtraAllproduct.find(f => f?.product_cycle_type === this.currentProduct?.product_cycle_type && f?.product_activity_type != 1)
    },
    xtraAutoPayLimitInfo() {
      return (this.limitedPayTypeList || []).map(m => m.code)
    },
    xtraCurrentAutoPayLimit() {
      return this.xtraAutoPayLimitInfo?.includes(this.cardPay)
    },
    // xtraAutoController end
  },
  watch: {
    usedXtraCode: {
      handler(n) {
        this.xtraSeleted = !!n
        this.autoPaymentVisibleFn()        
        this.getCardBaseStyle(n ? n : this.productInfo?.product_code)
        this.getEntryCouponStyle(n ? n : this.productInfo?.product_code)
      },
      immediate: true
    },
    couponListInfo: {
      handler: debounce({
        func: function (nVal) {
          if (!Array.isArray(nVal)) return
          // 是否存在超省卡勾选券
          const saverCouponUse = nVal.find(coupon => coupon.typeId == 55)
          this.$nextTick(() => {
            if (saverCouponUse && this.xtraSeleted && this.productTitle?.includes('saver-title-animation')) {
              this.startPriceRolling()
            }
          })
        },
        wait: 500
      }),
      deep: true,
    },
    xtraSeleted: {
      handler(nVal) {
        if (nVal && this.showSlideEffect && !this.hasSlide) {
          setTimeout(() => {
            setTimeout(() => {
              this.lightEffect = true
              this.hasSlide = true
            }, 400)
            
            setTimeout(() => {
              this.lightEffect = false
            }, 1200)
          }, 500)
        }
      }
    },
    currentCardPayRememberRadio: {
      handler(){
        this.autoPaymentVisibleFn()
      }
    },
    // currentAfterpayRememberRadio: {
    //   handler(){
    //     this.autoPaymentVisibleFn()
    //   }
    // },
    cardPay: {
      handler(){
        this.autoPaymentVisibleFn()
      },
      immediate: true
    },
    isShowAutoRenewedProtocol: {
      handler(val) {
        if (val && !this.isReportOnceEvent) {
          this.isReportOnceEvent = true
          daEventCenter.triggerNotice({
            daId: '2-43-12'
          })
        }
      },
      immediate: true,
    }
  },
  mounted(){
    this.isClient = true
  },
  methods: {
    template,
    getEntryCouponStyle(productCode){
      this.entryCouponStyle = getProductStyle(
        productCode,
        this.styleConfig?.productPackageStyle?.couponConfigList,
        this.styleConfig?.productPackageStyle?.couponConfig
      ) || { showCouponLimit: true }
    },
    getCardBaseStyle(productCode){
      this.cardBaseStyle = getProductStyle(
        productCode,
        this.styleConfig?.productPackageStyle?.basicConfigList,
        this.styleConfig?.productPackageStyle?.basicConfig
      ) || { showSubTitle: true }
    },
    setXtraCoupon(visible) {
      this.showXtraCoupon = visible
    },
    updateProductCodeInDrawer(productCode) {
      this.productCodeInDrawer = productCode
      
    },
    reverseXtra(xtraProductCodeInDrawer) {
      this.$emit('changeUpdateCalcu', { xtraSeleted: this.xtraSeleted, xtraProductCodeInDrawer })
    },
    clickXtra({ type = '', xtraProductCodeInDrawer }) {
      if ((type == 'all' && this.isHasAutoRenewedBag) || this.notSupport) return

      if (this.notSupport || this.hasAddress == 0) return

      if (this.isHasAutoRenewedBag) {
        this.xtraSeleted = !!xtraProductCodeInDrawer
      } else {
        this.xtraSeleted = !this.xtraSeleted
      }
      this.$emit('changeCouponInfo', this.xtraSeleted)
      this.reverseXtra(xtraProductCodeInDrawer)

      daEventCenter.triggerNotice({
        daId: '2-43-2', 
        extraData: {
          ...this.xtraOptionAnalysis
        }
      })
    },
    clickPolicy(e) {
      if (this.notSupport || this.hasAddress == 0) {
        e.preventDefault()
      }
      if(e.target?.tagName == 'A') {
        daEventCenter.triggerNotice({
          daId: '2-43-6',
          extraData: {
            scene: 'entrance'
          }
        })
      }
    },
    chooseXtra(flag, xtraProductCodeInDrawer) {
      this.xtraSeleted = flag
      this.reverseXtra(xtraProductCodeInDrawer)
    },
    clickDetails(ignoreDisabled) {
      const disabled = this.notSupport || this.hasAddress == 0
      if (!ignoreDisabled && disabled) return

      daEventCenter.triggerNotice({
        daId: '2-43-3',
        extraData: {
          original_price: this.xtraOptionAnalysis.original_price,
          special_price: this.xtraOptionAnalysis.special_price
        }
      })
      this.showXtraCoupon = true

      daEventCenter.triggerNotice({
        daId: '2-43-4',
        extraData: {
          original_price: this.xtraOptionAnalysis.original_price,
          special_price: this.xtraOptionAnalysis.special_price
        }
      })
    },
    radioSelectClickDisountCb(e, productCode){
      this.productCode = productCode
      this.clickDiscountTips(e)
    },
    clickDiscountTips(e, visible, extraDataEventOpt) {
      if(this.sceneLogic[this.productCode].isTips){
        daEventCenter.triggerNotice({
          daId: '2-43-14',
          extraData: {
            type: 'saver',
            position: 'page',
            ...extraDataEventOpt
          }
        })
        // 前往券列表
        e.stopPropagation()
        return this.goCouponPage()
      }
      if (!this.canShowDiscountTips && !this.sceneLogic[this.productCode].isVisible) return
      e.stopPropagation()
      if(this.sceneLogic[this.productCode].isVisible && !visible){
        // 优惠弹窗
        this.virtunalDiscountVisible = true
      } else {
        // 法务弹窗
        this.discountTipsVisible = true
      }
    },
    // 产品包数据更新事件，用于更新原子数据
    productBagClickEvent(data){
      this.$emit('updateCoreDataEvent', data)
    },
    autoPaymentVisibleFn(){
      // 针对paypal-gapaypal/afterpay-card 做额外处理，没有勾选remenber，则展示引导切快捷
      const cardPay = this.cardPay
      const currentCardPay =  SIGNUP_PAYMENT_METHOD.includes(cardPay)
      if(currentCardPay){
        this.autoPaymentVisible = !this.xtraCurrentAutoPayLimit || this.currentCardPayRememberRadio
      } else {
        this.autoPaymentVisible = !this.xtraCurrentAutoPayLimit
      }
    },

    dialogChangeEvent(e, productCode) {
      this.productCode = productCode || this.usedXtraCode || this.xtraProductNormal[0].product_code || 'default'

      if(this.sceneLogic[this.productCode].isLeg){
        this.clickDiscountTips(e, true, {
          type: 'saver',
          position: 'popup'
        })
      } else {
        this.clickDiscountTips(e, false, {
          type: 'saver',
          position: 'popup'
        })
      }
    },
    autoRenewdDrawerShow(productCode, isOnCouponDrawer){
      this.$emit('autoRenewdDrawerShow', productCode, isOnCouponDrawer)
    },
    startPriceRolling() {
      this.$nextTick(() => {
        const regx = /\D*([\d.,]+)/
        const els = document.getElementsByClassName('saver-title-animation')
        const el = Array.from(els)?.filter(el => el.innerText?.match(regx)[1] != el.innerText)?.[0] // 则判断存在货币符号，为金额数据

        handleTotalPriceAnimate(
          { ...this.titleInfo?.caculateDiscount },
          { amount: '0.00' },
          newTotalPrice => {
            if (el) {
              el.textContent = newTotalPrice?.amountWithSymbol
            }
          },
          currencies,
          currency
        )
      })
    },
  }
}
</script>

<style lang="less" scoped>
.pickColor_xtra{
  color: #F82854
}
.mrg-4{
    margin-left: -3px;
  }
.checkout-xtra__wrap {
  margin-top: 8px;

  .checkout-xtra__wrap-logo {
    width: 100%;
    height: 26px;
  }

  .checkout-xtra__wrap-body {
    display: flex;
    align-content: space-between;
    align-items: flex-start;
    padding: 8px 12px;
    cursor: pointer;

    .sui-radio {
      .margin-r(0);
    }
  }

  .checkout-xtra__wrap-content {
    width: 100%;
    min-width: 0;
  }

  .checkout-xtra__wrap-name {
    .checkout-xtra__wrap-price-special {
      color: @sui_color_gray_dark1;
      font-size: 16px;
      font-weight: 700;
      .margin-r(4px);

    }

    .checkout-xtra__price_ar {
      font-family: SF Pro;
    }

    .discount-price {
      color: #F82854;
    }
    .checkout-xtra__wrap-price-del {
      color: @sui_color_gray_light1;
      font-size: 14px;
      font-weight: 400;
      .margin-r(4px);
    }
    .checkout-xtra__wrap-price-local {
      color: #222;
      font-size: 12px;
      font-size: 12px;
      font-weight: 400;
      .margin-r(4px);
    }
    .checkout-xtra__wrap-price-discount {
      display: inline-block;
      border-radius: 6px 0px;
      background: linear-gradient(270deg, #F82854 0%, #DE00F1 100%);
      color: #FFF;
      font-size: 10px;
      font-weight: 600;
      padding: 2px 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      vertical-align: middle;
    }
    .checkoutXtra__activeXtra-dicount_bg{
      background: #FEE8ED;
      border: 1px solid #F74D81;
      color: #EB3662;
    }
  }

  .checkout-xtra__wrap-des {
    margin-top: 8px;
    font-size: 14px;
    em {
      color: #F82854;
    }
  }
  .checkout-xtra__NewWrapDes{
    color: var(--sub-title-color, #767676);
    font-family: "SF Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    // margin-top: 1px;
    /deep/ em {
      color: var(--sub-title-place-color, #F82854);
    }
  }
  .checkout-xtra__NewWrapDesTitle{
      font-family: "SF Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: var(--title-color, #000);
    /deep/ em {
      color: #F82854;
      font-weight: 860;
      color: var(--title-place-color, #F82854);
    }

    .line-camp(2)
  }

  .xtra-hearder{
    font-family: "SF Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 590;
    line-height: normal;
    /deep/ em {
      color: #F82854;
      font-weight: 860;
    }
  }

  .checkout-xtra__wrap-list {
    margin-top: 3px;
  }

  .checkout-xtra__wrap-coupon {
    margin-top: 8px;
  }
  .container-radio{
    display: flex;
    margin-top: 9px;
    align-items: center;
  }

  .checkout-xtra__wrap-policy {
    margin-top: 8px;
    font-size: 10px;
    color: var(--protocal-color, @sui_color_gray_light1);

    .polict-detail {
      color: @sui_color_link;
      cursor: pointer;
    }
    .auto-renewed-protocol {
      cursor: pointer;
      color: var(---sui_color_link, #2D68A8);
    }
  }

  .checkout-xtra__wrap-disabled {
    font-size: 12px;
    color: @sui_color_unusual;
    i {
      .margin-r(4px);
    }
    margin-top: 8px;
  }

  .disabled {
    opacity: 0.3;
  }

  .header-bg{
    position: absolute;
    top: 0;
    left: 0px;
  }
  .header-bg-container{
    position: relative;
    margin-top: -20px;
    font-size: 16px;
    .xtra-text{
      font-size: 24px;
      font-weight: 600;
      line-height: 1.7;
    }
  }

  .virXrt_container{
    margin-bottom: 36px;
    font-size: 14px;
    .virXrt_line{
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
    .virXrt-line_left{
        display: flex;
        justify-content: center;
        align-items: center;
      }
    .virXrt-lineLeft_tips{
      margin-right: 5px;
    }
  }
}
</style>

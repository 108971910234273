<template>
  <!-- 展示的顺序：地址相关提示, 台湾地区、 通用地址、后续兜底，见https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1513819203-->
  <p
    v-if="status.generalAddressTipRule"
    class="address-title-tips-screen-efficiency"
  >
    {{ status.generalAddressTipRule }}
  </p>
  <p
    v-else-if="checkout.default_address.countryId == '209'"
    v-expose="{ id: '1-11-1-108' }"
    class="address-title-tips-screen-efficiency"
    @click="clickTwJumpUrl"
    v-html="twJumpUrl ? adrNameVerifiedTitleTip : language.SHEIN_KEY_PC_20337"
  ></p>
  <p
    v-else-if="status.adrTitleSaTipsContent"
    class="address-title-tips-screen-efficiency"
  >
    {{ status.adrTitleSaTipsContent }}
  </p>
  <p
    v-else-if="
      checkout.default_address.countryId == '178'
    "
    class="address-title-tips-screen-efficiency"
    @click="clickAddressTips"
    v-html="htmlDecode({ text: language.SHEIN_KEY_PC_17197, isHtml: true })"
  ></p>
  <p
    v-else-if="
      checkout.default_address.countryId == '43'
    "
    class="address-title-tips-screen-efficiency"
    @click="clickAddressTips"
    v-html="htmlDecode({ text: language.SHEIN_KEY_PC_17337, isHtml: true })"
  ></p>
  <p
    v-else-if="
      checkout.default_address.countryId == '105'
    "
    class="address-title-tips-screen-efficiency"
  >
    {{ language.SHEIN_KEY_PC_17907 }}
  </p>
  <!-- 韩国 FR-8218-->
  <p
    v-else-if="
      checkout.default_address.countryId == '197'
    "
    class="address-title-tips-screen-efficiency"
    v-html="htmlDecode({ text: krCopywriteTip, isHtml: true })"
  ></p>
</template>

<script>
import { htmlDecode } from '@shein/common-function'

export default {
  name: 'AddressTitleTips',
  props: {
    adrNameVerifiedTitleTip: {
      type: String,
      default: ''
    },
    status: {
      type: Object,
      default: () => {}
    },
    checkout: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    krCopywriteTip: {
      type: String,
      default: ''
    },
  },
  computed: {
    twJumpUrl() {
      return !!(this.checkout.checkoutBFFAbtInfo?.TaiwanJumpUrl?.param == 'TaiwanDownloadOn')
    },
  },
  methods: {
    htmlDecode,
    clickTwJumpUrl(event) {
      this.$emit('click-tw-jump-url', event)
    },
    clickAddressTips(event) {
      this.$emit('click-address-tips', event)
    }
  },
  emits: ['click-tw-jump-url', 'click-address-tips']
}
</script>

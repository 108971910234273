<template>
  <div>
    <SaverProductPackage
      ref="saverProductPackageRef"
      :used-xtra-code="usedXtraCode"
      :payment-methods-props="paymentMethodsProps"
      :product-bag-info-list="allProductBagInfo"
      :coupon-list-info="coupon.applyCouponsSuccess"
      :display-coupon-info="displayCouponInfo"
      :aggregated-coupon-list="aggregatedCouponList"
      :coupon-list-drawer="couponList"
      :product-info="productInfo"
      :title-info="titleInfo"

      :bubble-text="bubbleText"
      :bubble-logo-list="logoList"
      :card-pay="currentCardPay"
      :not-support="notSupport"
      :product-title="title"
      :product-sub-title="subtitle"
      :has-address="hasAddress"
      :language="language"
      :limited-pay-type-list="limitedPayTypeList"
      :style-config="checkout.productPackageInfo.styleInfo.saverInfo"
      :locals="locals"
      :now-payment="nowPayment"

      :go-coupon-page="goCouponPage"
      :abt="abt"
      @change-coupon-info="changeCouponInfo"
      @change-update-calcu="changeUpdateCalcu"

      @update-core-data-event="updateCoreDataEvent"
      @auto-renewd-drawer-show="handlePayMethodsListDrawerShow"
    />

    <ClientOnly>
      <!-- 自动续费切换快捷支付弹窗 -->
      <PayMethodsListDialog 
        ref="PayMethodsListDialogRef" 
        :checkout="checkout"
        :language="language" 
        :payment-methods-props="paymentMethodsProps"
        :pay-methods-list-drawer-header-data="payMethodsListDrawerHeaderData"
        auto-type="xtra"
        :auto-payment-submit-emit="autoPaymentSubmitEmit"
      />
      <productAutoDrawer
        ref="productAutoDrawerRef"
        :language="language"
        :auto-type="autoType"
        @auto-submit="autoSubmit"
      />
    </ClientOnly>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick, reactive, watch } from 'vue'
import { useMapState, useMapGetters, useMapMutation, useMapActions } from 'public/src/pages/store_pages/store/mapHook'
import { template } from '@shein/common-function'
import useSaverTitle from './hooks/useSaverTitle.js'
import { SIGNUP_PAYMENT_METHOD } from 'public/src/pages/common/constants.js'

import SaverProductPackage from 'public/src/pages/common/product-package/saver/index.vue'
import PayMethodsListDialog from 'public/src/pages/checkout/components/payment_list_dialog/pay_methods_list_dialog.vue'
import productAutoDrawer from 'public/src/pages/checkout/components/product_auto_drawer/index.vue'

const props = defineProps({
  paymentMethodsProps: {
    type: Object,
    default: () => {}
  }
})

const payTypeList = ref([])
const logoList = ref([])
const payMethodsListDrawerHeaderData = ref({})
const autoType = ref('xtra')
const autoRenewalProductCode = ref('')
const nextPaymentInfo = ref(null)
const saverDrawerVisible = ref(false)
const saverProductPackageRef = ref(null)
const PayMethodsListDialogRef = ref(null)
const productAutoDrawerRef = ref(null)

const {
  language, 
  checkout, 
  xtraAllProductBagInfo, 
  status, 
  coupon, 
  usedXtraCode,
  primeProductList,
  locals,
} = useMapState([
  'language', 
  'checkout', 
  'xtraAllProductBagInfo', 
  'status', 
  'coupon', 
  'usedXtraCode',
  'primeProductList',
  'locals',
])

const {
  currentSaverProduct,
  xtraAutoController,
  productAutoInfo 
} = useMapGetters([
  'currentSaverProduct',
  'xtraAutoController',
  'productAutoInfo' 
])

const {
  assignState, 
  changeVirtualCouponInfo, 
  changeParamsStatus, 
  upDateSceneStatusNew, 
  updateCheckoutState
} = useMapMutation([
  'assignState', 
  'changeVirtualCouponInfo', 
  'changeParamsStatus', 
  'upDateSceneStatusNew', 
  'updateCheckoutState'
])

const {
  changeXtraPrimeUpDateCalcu, 
} = useMapActions([
  'changeXtraPrimeUpDateCalcu', 
])

const abt = computed(() => ({
  saverOrderModule: checkout.value?.checkoutBFFAbtInfo?.saverOrderModule
}))

const allProductBagInfo = computed(() => {
  if (xtraAutoController.value.sheinSaverRenewAbt) {
    return xtraAllProductBagInfo.value
  } 
  let normalBagInfo = {}
  Object.values(xtraAllProductBagInfo.value)
    .filter(info => info.activityType != 1)
    .forEach(info => {
      normalBagInfo[info.productInfo.product_code] = info
    })
  return normalBagInfo
})
const aggregatedCouponList = computed(() => currentSaverProduct.value.couponListInfo.aggregatedCouponList || [])
const couponList = computed(() => currentSaverProduct.value.couponListInfo.couponList || [])
const discountInfo = computed(() => currentSaverProduct.value.discountInfo || {})
const displayCouponInfo = computed(() => currentSaverProduct.value.displayCouponInfo || {})
const limitedPayTypeList = computed(() => currentSaverProduct.value.limitedPayTypeList || [])
const productInfo = computed(() => currentSaverProduct.value.productInfo || {})

const caculateDiscount = computed(() => checkout.value?.mall_caculate_info?.save_card_discount_price)
const titleInfo = reactive({
  discountInfo,
  caculateDiscount,
})

const { title, subtitle } = useSaverTitle({ discountInfo, displayCouponInfo, productInfo })

const currentCardPay = computed(() => status.value?.cardPay)
const banksConfig = computed(() => checkout.value?.bankSelect?.banksConfig)
const paymentMethods = computed(() => checkout.value.results?.paymentMethods || [])
const nowPayment = computed(() => {
  if(!status.value?.cardPay) return {}
  return paymentMethods.value?.filter(item => item.code == status.value.cardPay)?.[0] || {}
})
// 不可勾选状态
const notSupport = computed(() => nowPayment.value.support_xtra_with_order == 0 || status.value?.hasAddress == 0)
const hasAddress = computed(() => status.value?.hasAddress)

const bubbleText = computed(() => template(payTypeList.value?.length, language.value?.SHEIN_KEY_PC_29641))

const paymentDisplay = (code) => {
  if (
    props.paymentMethodsProps.paymentDisplayAgain &&
    typeof props.paymentMethodsProps.paymentDisplayAgain == 'function'
  ) {
    return props.paymentMethodsProps.paymentDisplayAgain(code)
  } else {
    return true
  }
}

const goCouponPage = () => {
  // 只有第一次展示红点
  changeParamsStatus({
    type: 'coupon',
    params: {
      showCouponList: true
    }
  })
  updateCheckoutState({
    key: 'hasEnterCouponList',
    val: true
  })
}

// 获取支付方式logo
const getPaymentLogoUrl = (item) => {
  // 1.如果是cod支付方式，直接返回logo
  if (item.code?.toLowerCase() == 'cod') {
    return item.logo_url
  }
  // 2.如果支付方式enabled不为0，返回银行配置的logo或者支付方式logo
  if (item.enabled != 0) {
    return (
      banksConfig.value?.[item.code]?.bankLogo || item.logo_url
    )
  }
  // 3.其他情况返回支付方式logo
  return item.logo_url
}

const updateCoreDataEvent = (data) => {
  const usedXtraCode = data?.usedXtraCode || data?.selectProductInfo?.productInfo?.product_code
  assignState({ usedXtraCode })
}

// 业务逻辑更新事件 start
const changeCouponInfo = () => {
  changeVirtualCouponInfo({
    currentClickType: 'xtra'
  })
}
const changeUpdateCalcu = (data) => {
  if (typeof window === 'undefined') return
  assignState({ usedXtraCode: data.xtraProductCodeInDrawer })
  window.checkoutEventCenter.emit('change-xtra-calcu', data)
}
// 自动续费支付方式弹窗
const handlePayMethodsListDrawerShow = (productCode, isOnCouponDrawer = false) => { 
  const productInfo = xtraAutoController.value.xtraProductAuto.find(f => f.product_code == productCode) || xtraAutoController.value.xtraProductAuto[0] || {}
  const { product_name_language, product_price_info, product_code } = productInfo || {}
  const {
    total_discount_price_with_symbol,
    arrival_price_with_symbol,
    price_local_with_symbol
  } = product_price_info || {}
  payMethodsListDrawerHeaderData.value = {
    product_name_language,
    total_discount_price_with_symbol,
    arrival_price_with_symbol,
    price_local_with_symbol,
    product_code
  }
  autoRenewalProductCode.value = productCode
  PayMethodsListDialogRef.value.handlerShow()
  saverDrawerVisible.value = isOnCouponDrawer
}
const autoPaymentSubmitEmit = () => {
  autoSubmitPayment({ 
    productCode: autoRenewalProductCode.value, 
    DwVisible: saverDrawerVisible.value  
  })
}
// 业务逻辑更新事件 end

// 切包回调
const autoSubmit = ({ productCode, isdont } = { productCode: '', isdont: false }) => {
  const switchProductCode = productCode

  if(switchProductCode) { 
    window.checkoutEventCenter.emit('again-choose-payment', { paymentInfo: nextPaymentInfo.value, isdont, afterCallBack: ({ isdont }) => {
      // 封装的选中按钮组件
      let options = null
      if(isdont){
        // 取消包勾选
        assignState({ autoCulateApi: true, usedXtraCode: '' })
        if(autoType.value == 'common'){
          assignState({ usedPrimeIndex: -1, usedPrimeCode: '' })
          options = { xtraSeleted: false, primeSeleted: false }
        }
      } else {
        if(autoType.value == 'common'){
          options = { xtraSeleted: true, primeSeleted: true }
        }
      }
      changeXtraProductInfo({ currentCode: switchProductCode, options })
    } })
  }
}

// 支付方式切包回调(内页和外页)
const autoSubmitPayment = ({ productCode, DwVisible }) => {
  const switchProductCode = productCode
  assignState({
    autoCulateApi: true,
    usedXtraCode: switchProductCode || ''
  })
  
  changeXtraProductInfo({ currentCode: switchProductCode, DwVisible, type: 'direct' })
}

// 切包更新值
const changeXtraProductInfo = ({ currentCode, DwVisible, type, options } = { currentCode: '', DwVisible: false, type: 'init', options: null }) => {
  // 两个包需要同时更新
  if(options){
    if(options?.xtraSeleted && options?.primeSeleted){
      assignState({ usedXtraCode: currentCode })
      const { currentProductCycleDays } = productAutoInfo.value
      const selectdPrimeIndex = primeProductList.value?.findIndex( item => currentProductCycleDays?.product_code == item.product_code)
      assignState({
        usedPrimeIndex: selectdPrimeIndex,
        usedPrimeCode: primeProductList.value?.[selectdPrimeIndex]?.product_code
      })
    }
    return changeXtraPrimeUpDateCalcu(options)
  }
  if(DwVisible){
    // 内页回调
    nextTick(() => {
      saverProductPackageRef.value?.chooseXtra(true, currentCode)
      saverProductPackageRef.value?.setXtraCoupon(false)
    })
  } else {
    // 非init 直接调选择
    if(type == 'direct'){
      nextTick(() => {
        saverProductPackageRef.value?.chooseXtra(true, currentCode)
      })
    } else {
      nextTick(() => {
        saverProductPackageRef.value?.chooseXtra(true, currentCode)
      })
    }
  }
}

// 唤起切包逻辑
const bubbleEvent = (opt) => {
  const { title, code, logo_url } = opt?.item || { title: '', code: '', logo_url: '' }
  const { currentProduct, currentProductCycleDays } = xtraAutoController.value || {}
  const paymentInfo = { title, code, logo: logo_url }
  productAutoDrawerRef.value.visibleEvent( { visible: true, currentProduct, currentProductCycleDays, paymentInfo, normalShow: true })
}

// 自动续费支付方式过滤
const filterPayMethods = () => {
  // 自动续费限制支付方式

  // 支付方式列表
  // 过滤掉不可用的支付方式
  const newPaymentMethods = paymentMethods.value?.filter((item) => {
    if(SIGNUP_PAYMENT_METHOD.includes(item.code)){
      // const express = props.paymentMethodsProps?.paypalGaVault?.express
      // const valid = props.paymentMethodsProps?.paypalGaVault?.valid
      // const isShow = express && valid
      // const otherShow = props.paymentMethodsProps?.paypalGaVault?.valid &&
      //   item.enabled == 1 &&
      //   paymentDisplay(item.code) &&
      //   props.paymentMethodsProps?.needPayInlinePaymentMethods?.indexOf(item.code) > -1 &&
      //   props.paymentMethodsProps?.paypalGaVault?.visibleArrow

      const isShow =
      item.enabled == 1 &&
          paymentDisplay(item.code) &&
      !!((
        props.paymentMethodsProps.signupPayVault?.[item.code]?.valid 
      ) || 
        props.paymentMethodsProps.signupAccountInfo?.[item.code]?.id
      )

      return item.enabled != 0 && isShow
    } else {
      return item.enabled != 0
    }
  })

  // 过滤自动续费限制支付方式
  // （1）支付方式code数组
  const tempList =  limitedPayTypeList.value?.map((payType) => {
    return payType.code
  })
  // （2）筛选出符合条件的支付方式
  let logoListTemp = newPaymentMethods?.filter((item) => {
    if(tempList.includes(item.code)){
      return true
    } else {
      return false
    }
  })
  payTypeList.value = [...logoListTemp]
  // （3）取出支付方式logo
  logoListTemp = logoListTemp?.map((item) => {
    return getPaymentLogoUrl(item)
  })
  // （4）限制最多叠放3个支付方式
  if(logoListTemp.length > 3){
    logoListTemp = logoListTemp.slice(0, 3)
  }
  logoList.value = logoListTemp
}

const handleEventListeners = () => {
  // 监听切换支付方式，自动续费包弹窗 
  window?.checkoutEventCenter &&  window?.checkoutEventCenter.on('payment-choosepayment-event', (opt)=>{
    if(['xtra', 'common'].includes(opt.currentAutoProductDwType) ) {
      autoType.value = opt.currentAutoProductDwType
      bubbleEvent(opt)
      nextPaymentInfo.value = opt
    }
  })
}

onMounted(() => {
  filterPayMethods()
  upDateSceneStatusNew()
  handleEventListeners()
})


watch(
  () => props.paymentMethodsProps, 
  () => {
    filterPayMethods()
  }
)

watch(
  () => props.paymentMethodsProps.signupPayVault, 
  () => {
    filterPayMethods()
  },
  { deep: true }
)

</script>
